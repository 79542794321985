// react
import React from 'react';

// packages
import { v4 as uuidv4 } from 'uuid';

// types
import type { FormEvent } from 'react';

const inputStyles = {
  marginTop: '8px',
  padding: '6px 8px',
};

const labelStyles = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '12px',
  minWidth: '50%',
  width: 'fit-content',
};

const sectionStyles = {
  margin: '32px 16px',
};

const Input = function ({
  form,
  handleInput,
  id,
}: {
  form: Record<string, string>;
  handleInput: (e) => void;
  id: string;
}) {
  return (
    <label htmlFor={id} style={labelStyles as any}>
      {id.toUpperCase()}
      <input
        id={id}
        name={id.toUpperCase()}
        type="text"
        value={form[id]}
        onInput={handleInput}
        style={inputStyles as any}
      />
    </label>
  );
};

const initialState = {
  tac: '',
  tran_code: 'SALE',
  cust_nbr: '9001',
  merch_nbr: '900156',
  dba_nbr: '1',
  terminal_nbr: '1',
  amount: '1.99',
  tran_nbr: '1',
  account_nbr: '4111111111111111',
  cvv2: '123',
  exp_date: '2512',
  redirect_echo: 'V',
  response_echo: 'V',
  user_data_1: uuidv4(),
};

const EPX = function () {
  // state
  const [browserPostURL, setBrowserPostURL] = React.useState(
    'https://services.epxuap.com/browserpost/'
  );
  const [isTACLoading, setIsTACLoading] = React.useState(false);
  const [form, setForm] = React.useState(initialState);

  const fetchData = React.useCallback(async () => {
    try {
      if (isTACLoading || form.tac) return;

      setIsTACLoading(true);

      const redirectURL = 'https://api.dev.protecht.com/api/internal/epx';

      const res = await fetch(
        'https://api.dev.protecht.com/api/internal/epx/tac',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-super-secret': 'my-name-jake',
          },
          body: JSON.stringify({
            mac: '2ifP9bBSu9SVzcw6vGFIxI51AR35sfus',
            amount: '1.99',
            tran_nbr: '1',
            tran_group: 'SALE',
            redirect_url: redirectURL,
            invalid_redirect_url: redirectURL,
            response_url:
              'https://api.dev.protecht.com/api/internal/epx/posterman',
            user_data_1: form.user_data_1,
          }),
        }
      );

      // eslint-disable-next-line no-alert
      if (!res.ok) alert('Could not generate the TAC');

      const body = await res.json();

      setForm((prevState) => ({
        ...prevState,
        tac: body,
      }));

      // eslint-disable-next-line no-console
      console.log(body);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [form.tac, form.user_data_1, isTACLoading]);

  React.useLayoutEffect(() => {
    fetchData();
  }, [fetchData]);

  // functions
  const handleInput = (e: FormEvent<HTMLInputElement>) => {
    const event = e.target as HTMLInputElement;

    setForm((prevState) => ({
      ...prevState,
      [event.id]: event.value,
    }));
  };

  return (
    <section style={sectionStyles as any}>
      <header>
        <h1>EPX Browser Post</h1>
      </header>
      <div style={{ maxWidth: '600px' }}>
        <label htmlFor="browser_post_url" style={labelStyles as any}>
          BROWSER_POST_URL
          <input
            id="browser_post_url"
            type="text"
            value={browserPostURL}
            onInput={(e) =>
              setBrowserPostURL((e.target as HTMLInputElement).value)
            }
            style={inputStyles as any}
          />
        </label>
        <form
          action={browserPostURL}
          method="post"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {Object.keys(initialState).map((id) => (
            <Input key={id} form={form} handleInput={handleInput} id={id} />
          ))}
          <button style={{ width: 'fit-content' }} type="submit">
            Submit payment
          </button>
        </form>
      </div>
    </section>
  );
};

export default EPX;
